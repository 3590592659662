import {
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_GRID,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
} from '@wix/communities-blog-client-common/dist/src/constants/layout-types';
import {
  HOVERING_BEHAVIOUR,
  OPTION_GALLERY_SIZE_TYPE,
  VIDEO_PLAY,
  OVERLAY_ANIMATION,
  IMAGE_HOVER_ANIMATION,
  IMAGE_LOADING_MODE,
  SCROLL_ANIMATION,
  CUBE_TYPE,
  TEXT_PLACEMENT,
  CALCULATE_TEXT_BOX_HEIGHT,
  TEXT_BOX_WIDTH_CALCULATION_OPTIONS,
} from '../../../common/constants/pro-gallery-options';

export const createOptions = (layout, options, itemBorder) => {
  const commonGalleryOptions = {
    gallerySizeType: OPTION_GALLERY_SIZE_TYPE.PX,
    gallerySizePx: options.layoutPostSize,
    imageMargin: options.layoutSpacing,
    enableInfiniteScroll: true,
    videoPlay: VIDEO_PLAY.HOVER, // can be 'auto'
    allowTitle: true,
    overlayAnimation: OVERLAY_ANIMATION.FADE_IN,
    imageHoverAnimation: IMAGE_HOVER_ANIMATION.ZOOM_IN,
    imageLoadingMode: IMAGE_LOADING_MODE.BLUR,
    scrollAnimation: SCROLL_ANIMATION.GRAYSCALE,
    imageInfoType: 'ATTACHED_BACKGROUND',
    textsHorizontalPadding: -30,
    itemBorderWidth: itemBorder.width,
    itemBorderColor: itemBorder.color,
  };

  switch (layout) {
    case LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        cubeImages: true,
        cubeType: CUBE_TYPE.FILL,
        cubeRatio: options.layoutImageRatio,
        titlePlacement: TEXT_PLACEMENT.SHOW_ON_HOVER,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NO_CHANGE,
      };
    case LAYOUT_PG_SIDE_BY_SIDE:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        isVertical: true,
        cubeType: CUBE_TYPE.FILL,
        cubeRatio: options.layoutImageRatio,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: options.textBoxAlignment,
        calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        textBoxWidthPercent: options.textBoxProportions,
      };
    case LAYOUT_PG_CARD_MEDIUM:
      return {
        ...commonGalleryOptions,
        galleryLayout: 1,
        isVertical: true,
        cubeImages: true,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
    case LAYOUT_PG_GRID:
    default:
      return {
        ...commonGalleryOptions,
        galleryLayout: 2,
        isVertical: true,
        cubeImages: true,
        cubeRatio: options.layoutImageRatio,
        calculateTextBoxHeightMode: CALCULATE_TEXT_BOX_HEIGHT.MANUAL,
        textBoxHeight: options.layoutContentHeight,
        textsVerticalPadding: -15,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW, // can be 'SHOW_ABOVE'
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
      };
  }
};

// link to pro-gallery playground: https://pro-gallery.surge.sh
